/* eslint-disable no-nested-ternary */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { connect } from 'react-redux';
import dynamic from 'next/dynamic';

import theme from '../../theme/theme';
import useEventListener from '../../utils/hooks/useEventListener';
import manageBodyOverflow from '../../utils/manageBodyOverflow';

const NavigationPrimary = dynamic(() => import('../../molecules/navigation-primary'));
const NavigationSecondary = dynamic(() => import('../../molecules/navigation-secondary'));
const NavigationSecondaryNoChildren = dynamic(() => import('../../molecules/navigation-secondary/no-children'));
const NavigationTertiary = dynamic(() => import('../../molecules/navigation-tertiary'));

const Navigation = styled.nav`
  background-color: ${(props) => props.theme.color.brand};
  height: 100vh;
  max-width: 28rem;
  overflow: hidden;
  position: fixed;
  top: 0;
  transform: translateX(-100%);
  transition: transform ${(props) => props.theme.animation.default} ${(props) => props.theme.animation.easeOutQuart};
  width: 100%;
  z-index: ${(props) => props.theme.layers.audience};
  ${(props) => (props.isActive ? css`transform: translateX(0);` : '')}

  -webkit-backface-visibility: hidden;
  -webkit-transform-style: preserve-3d;

  > * {
    -webkit-backface-visibility: hidden;
    -webkit-transform-style: preserve-3d;
  }

  ul:empty {
    display: none;
  }

  @media (min-width: ${(props) => props.theme.breakpoint.small}) {
    max-width: 38rem;
  }

  @media (min-width: ${(props) => props.theme.breakpoint.medium}) {
    background-color: ${(props) => props.theme.color.white};
    border-bottom: 1px solid ${(props) => props.theme.color.background};
    height: 50px;
    max-width: initial;
    overflow: initial;
    padding-bottom: 0;
    position: sticky;
    top: 74px;
    transform: translateX(0);
    z-index: ${(props) => props.theme.layers.downstage - 2};

    > * {
      -webkit-backface-visibility: initial;
      -webkit-transform-style: initial;
    }
  }
`;

const MainNavigation = (props) => {
  const { navigation, isActive, isMobileDevice } = props;
  const [isMobile, setIsMobile] = useState(isMobileDevice);

  const handleOnChange = (e) => {
    const element = e.target;
    const { parentElement } = element;
    const checkboxes = global.document.querySelectorAll(`input[name="${element.getAttribute('name')}"]`);

    parentElement.setAttribute('aria-expanded', element.checked);

    [...checkboxes]
      .filter((el) => el !== element)
      .forEach((el) => {
        const checkbox = el;
        const parent = el.parentElement;
        checkbox.checked = false;
        parent.setAttribute('aria-expanded', false);
      });
  };

  const setMobileOnResize = () => {
    const { window } = global;
    if (window && window.matchMedia(`(max-width: ${theme.breakpoint.max('medium')})`).matches) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEventListener('resize', setMobileOnResize);

  useEffect(() => {
    manageBodyOverflow(isActive && isMobile);
  }, [isActive, isMobile]);

  const renderTertiary = (parent, removePointerEvents) => (navigation ? (
    <NavigationTertiary
      navigation={navigation}
      parent={parent}
      removePointerEvents={removePointerEvents}
      isMobile={isMobile}
    />
  ) : null);

  const renderSecondary = (parent) => (
    <NavigationSecondary
      handleChange={handleOnChange}
      navigation={navigation}
      renderTertiary={renderTertiary}
      parent={parent}
      isMobile={isMobile}
    />
  );

  const renderSecondaryNoChildren = (parent) => (
    <NavigationSecondaryNoChildren
      handleChange={handleOnChange}
      navigation={navigation}
      renderTertiary={renderTertiary}
      parent={parent}
      isMobile={isMobile}
    />
  );

  const renderPrimary = () => (
    <NavigationPrimary
      isMobile={isMobile}
      isActive={isActive}
      handleChange={handleOnChange}
      navigation={navigation}
      renderSecondary={renderSecondary}
      renderSecondaryNoChildren={renderSecondaryNoChildren}
    />
  );

  return (
    <Navigation
      isActive={isActive}
      isMobile={isMobile}
    >
      {
        navigation && Object.keys(navigation).length > 0
          ? renderPrimary(navigation)
          : null
      }
    </Navigation>
  );
};

MainNavigation.defaultProps = {
  isActive: false,
  isMobileDevice: false
};

MainNavigation.propTypes = {
  navigation: PropTypes.object.isRequired,
  isActive: PropTypes.bool,
  isMobileDevice: PropTypes.bool
};

const mapStateToProps = (state) => ({
  isActive: state.interfaces.isNavigationActive,
  isMobileDevice: state.interfaces.isMobileDevice
});

const mapDispatchToProps = () => ({});

const ConnectedMainNavigation = connect(mapStateToProps, mapDispatchToProps)(MainNavigation);

MainNavigation.displayName = 'MainNavigation';

MainNavigation.whyDidYouRender = true;

export default ConnectedMainNavigation;
